import { render, staticRenderFns } from "./EcologyAgricultureForm.vue?vue&type=template&id=65516417&scoped=true&lang=html&"
import script from "./EcologyAgricultureForm.vue?vue&type=script&lang=js&"
export * from "./EcologyAgricultureForm.vue?vue&type=script&lang=js&"
import style0 from "./EcologyAgricultureForm.vue?vue&type=style&index=0&id=65516417&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65516417",
  null
  
)

export default component.exports