<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else class="ecology-agriculture">
      <EcologyInputGrid>
        <DlgGridHeader>
          1. <IxRes>dlgCert.ecology.agriculture.headers.winterGroundCover</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="winterGroundCover"
          show-percent-of="cultivated"
          @input="update"
        >
          <template #description>
            <IxRes>dlgCert.ecology.agriculture.info.winterGroundCover</IxRes>
          </template>
        </EcologyInputLine>
        <DlgGridHeader>
          2. <IxRes>dlgCert.ecology.agriculture.headers.extensive</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          v-for="name in ['noChemicals', 'noNitrogen']" :key="name"
          :data="data" :name="name"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="noPlow"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="grasslandConversion"
          show-percent-of="cultivated"
          @input="update"
        />

        <DlgGridHeader>
          3. <IxRes>dlgCert.ecology.agriculture.headers.features</IxRes>
        </DlgGridHeader>
        <DlgGridHelpBox>
          <IxRes>dlgCert.ecology.agriculture.hints.features</IxRes>
        </DlgGridHelpBox>
        <EcologyInputLine
          :data="data" name="landscapeFeaturesTotalArea"
          show-percent-of="cultivated"
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="additionalFeaturesTotalArea"
          show-percent-of="cultivated"
          @input="update"
        >
          <template #description>
            <LandscapeFeatureDescription />
          </template>
        </EcologyInputLine>
        <EcologyInputLine
          :data="data" name="uniqueLandscapeFeatures"
          unit=""
          @input="update"
        >
          <template #description>
            <IxRes>dlgCert.ecology.grassland.descriptions.DifferentFeatureCountDescription</IxRes>
          </template>
        </EcologyInputLine>

        <DlgGridHeader>
          4. <IxRes>dlgCert.ecology.agriculture.headers.ecologicalPriorityAreas</IxRes>
        </DlgGridHeader>
        <DlgGridHelpBox>
          <IxRes>dlgCert.ecology.agriculture.hints.ecologicalPriorityAreas</IxRes>
        </DlgGridHelpBox>
        <EcologyInputLine
          v-for="name in ['stripeOnForest',
                          'shoreVegetation',
                          'bufferStrips',
                          'flowerStrips',
                          'fieldBorder',
                          'fallowLand',
                          'fallowHoneyPlant',
                          'agroforstsysteme',
                          'catchCropWithCultivation',
                          'wideSeed']"
          :key="name"
          :data="data" :name="name"
          show-percent-of="cultivated"
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="underseed"
          show-percent-of="cultivated"
          locked
        />
        <EcologyInputLine
          :data="data" name="speciesPromotion"
          unit="Maßnahmen"
          @input="update"
        />

        <DlgGridHeader>
          5. <IxRes>dlgCert.ecology.agriculture.headers.partialFieldArea</IxRes>
        </DlgGridHeader>
        <EcologyInputLine :data="data" name="averageArableParcelSize" />

        <DlgGridHeader>
          6. <IxRes>dlgCert.ecology.agriculture.headers.cultivationDiversity</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          v-for="name in ['grasses',
                          'legumes',
                          'amaranths',
                          'daisyFamily',
                          'umbellifers',
                          'amaryllidaceae',
                          'cucurbits',
                          'mustards',
                          'nightshades',
                          'other']"
          :key="name"
          :data="data" :name="name"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="countCropTypes"
          unit=""
        />
      </EcologyInputGrid>
    </div>
  </transition>
</template>

<script>
import EcologyInputGrid from './elements/EcologyInputGrid'
import DlgGridHeader from './elements/DlgGridHeader'
import DlgGridHelpBox from './elements/DlgGridHelpBox'
import EcologyInputLine from './elements/EcologyInputLine'
import LandscapeFeatureDescription from './shared/LandscapeFeatureDescription'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    LandscapeFeatureDescription,
    EcologyInputGrid,
    DlgGridHeader,
    DlgGridHelpBox,
    EcologyInputLine
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/agriculture`
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
</style>
